html, body {
  font-family: 'Mada', sans-serif;
  overflow-x: hidden;
}

.compliment_heading {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 15px;
}

.client_compliment {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.client_name {
  font-size: 18px;
  font-weight: 400;
}

.slider_seperator {
  width: 200px;
  border-radius: 10px;
  height: 4px;
  background: yellow;
  margin: 25px auto;
}

.loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
}

.centered_paragraphs {
  margin: 0px 80px;
  margin-top: 40px;
  text-align: center;
}

.navbar {
  height: 100px;
  z-index: 1000;
  position: absolute !important;
  width: 100%;
  top: 0px;
  padding: 0px !important;
}

.pad_nav_logo {
  margin-left: 120px;
  cursor: pointer;
  position: relative;
}

.navbar-toggler , .justify-content-end {
  margin-right: 120px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
  font-weight: 500;
  -webkit-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
}

.navbar-dark:hover .navbar-nav:hover .nav-link:hover {
  color: #F4E04D !important;
}

.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
  color: #F4E04D !important;
}

.background_nav_logo_container {
  position: absolute;
  top: 0px;
  height: 100px;
}

.background_nav_logo {
  position: relative;
  width: 275px;
  padding: 10px 20px 10px 10px;
  font-size: 20px;
  position: relative;
  background: #fff;
  height: 100%;
}

.background_nav_logo:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #fff;
  -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
          transform-origin: bottom left;
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}

.padded_link {
  margin: 0px 10px;
  font-size: 16px;
  color: #546e7a;
}

.slide_contain {
  height: 100vh;
  height: 100vh;
  height: 100vh;
}

.slide, .slide_item {
  height: 100%;
}

.slide_image {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.axis_law_banner_container {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
}

.axis_law_banner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 3;
}

.color_overlay {
  background-image: -webkit-gradient(linear, right top, left bottom, from(#282d64), color-stop(#223367), color-stop(#1d3869), color-stop(#193d6b), to(#17426c));
  background-image: -o-linear-gradient(right top, #282d64, #223367, #1d3869, #193d6b, #17426c);
  background-image: linear-gradient(to left bottom, #282d64, #223367, #1d3869, #193d6b, #17426c);
  background-image: -webkit-gradient(linear, left bottom, right top, from(#222f55), color-stop(#1f325a), color-stop(#1b3460), color-stop(#163765), to(#0c3a6b));
  background-image: -o-linear-gradient(left bottom, #222f55, #1f325a, #1b3460, #163765, #0c3a6b);
  background-image: linear-gradient(to right top, #222f55, #1f325a, #1b3460, #163765, #0c3a6b);
  opacity: 0.825;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}

.axis_main_banner {
  font-size: 72px;
  color: #eee;
  text-transform: uppercase;
  font-family: 'Alata', sans-serif;
  font-weight: 500;
}
.axis_main_separator {
  width: 155px;
  margin-top: -5px;
  height: 4px;
  background: #F4E04D;
  border-radius: 50px;
}

.home_scroll {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
}

.home_social_bar {
  position: absolute;
  bottom: 0px;
  height: 90px;
  width: 25%;
  right: 0px;
  background: #9e9e9e;
  opacity: 0.75;
  z-index: 1;
  border-top-left-radius: 10px;
  display: none;
}

.home_social {
  color: #fff;
  cursor: pointer;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.home_social:hover {
  color: #F4E04D;
}

.slide_contain > .carousel > .carousel-control-prev , .slide_contain > .carousel > .carousel-control-next {
  display: none !important;
}

.content_container {
  margin: 35px 120px;
}

.grey_background {
  background: #eee;
  background-image: url(./resources/greek-vase.png);
}

.heading {
  color: #000000;
  font-family: 'Alata', sans-serif;
  text-align: center;
  font-size: 28px;
  margin-bottom: 0px;
}

.subheading, .smallheading {
  color: #000000;
  font-family: 'Alata', sans-serif;
  text-align: center;
  font-size: 24px;
  margin: 10px 0px;
}

.smallheading {
  font-size: 18px;
}

.heading_icon_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 35px;
}

.heading_icon {
  font-size: 30px;
  color: #455A64;
  margin-right: 8px;
}

.button {
  padding: 10px 30px;
  height: 35px;
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #d38b41;
  border-radius: 0px;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.08);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.08);
  cursor: pointer;
  background: #000000;
}

.button_text {
  margin: 0px;
  color: white;
}


.homeabout, .career_photos_container {
  position: relative;
  padding: 40px 0px;
}

.career_photos_container {
  margin-top: -50px;
}

.home_about_content {
  margin: 25px 325px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.home_about_content > p {
  text-align: center;
}

.career_photos_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 3;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow-x: hidden;
}

.contact_image_container {
  position: relative;
}

.contact_image {
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.contact_image_o {
  opacity: 0;
}

.home_about_bg {
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), color-stop(#f3f3f3), color-stop(#f1f2f1), color-stop(#f0f0f0), to(#eeeeee));
  background-image: -o-linear-gradient(top, #f5f5f5, #f3f3f3, #f1f2f1, #f0f0f0, #eeeeee);
  background-image: linear-gradient(to bottom, #f5f5f5, #f3f3f3, #f1f2f1, #f0f0f0, #eeeeee);;
  width: 100%;
  height: 100%;
}

.home_about_diag {
  background: #eee;
  position: absolute;
  width: 100%;
  height: 350px;
  top: 64%;
  left: 0px;
  -webkit-transform: skewY(-6.25deg);
      -ms-transform: skewY(-6.25deg);
          transform: skewY(-6.25deg);
  z-index: -1;
}

.home_locations_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0px 60px;
}

.home_location {
  width: 33.3%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.home_location_loc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 16px);
}

.home_location:first-child {
  border-right: 1px solid;
  padding: 10px 15px 10px 0px;
  border-color: #e0e0e0;
}

.home_location:nth-child(2) {
  border-right: 1px solid;
  border-color: #e0e0e0;
  padding: 10px 15px 10px 15px;
}

.home_location:last-child {
  padding: 10px 0px 10px 15px;
}

.home_services_container {
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.08);
          box-shadow: 0 6px 12px rgba(0,0,0,0.08);
  background-color: white;
  position: relative;
  padding: 45px 80px;
  margin-top: -120px;
  margin-bottom: 60px;
}

.home_services {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home_service {
  min-height: 90px;
  -webkit-transition-duration:0.6s;
       -o-transition-duration:0.6s;
          transition-duration:0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
       -o-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


#PracticeAreas_desc, #Industries_desc {
  display: none;
}

.service_button_seperator {
  width: 100%;
  margin-top: 15px;
  height: 2px;
  background: #eee;
}

.home_service_title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
}

.home_service_description {
  font-size: 14px;
  margin-bottom: 8px;
}

.home_services_banner_container {
  width: 50%;
  display: none;
}

.home_services_banner {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
}

.home_news_container {
  position: relative;
  margin-top: 60px;
}

.home_news_colored_portion {
  width: 100%;
  background: #e0e0e0;
  background-image: url('./resources/floor-tile.png');
  height: calc(100%);
  position: absolute;
  top: 0px;
  z-index: -1;
}

.home_news_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.home_news_banner_section {
  width: 57%;
  background: #3E2D4C;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  padding: 20px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.home_news_banner_section:hover {
  background: #20132d;
}

.absolute_particles {
  position: absolute;
  top: 0px;
  left: 0px;
}

.home_news_banner_title {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.home_news_banner_written_by {
  color: rgb(255, 235, 59);
  font-family: 'Alata', sans-serif;
}

.home_news_other_section {
  width: 43%;
  padding: 20px;
  background: white;
}

.home_news_other {
  cursor: pointer;
}

.home_news_other:first-child {
  border-bottom: 1px solid;
  margin-bottom: 18px;
  border-color: #757575;
}

.home_news_other_title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-decoration: underline;
}

.home_news_other_categ {
  font-size: 14px;
  margin: 5px 0px;
  text-transform: uppercase;
  font-family: 'Alata', sans-serif;
  color: #6c828c;
}

.home_news_other_desc {
  font-size: 14px;
}

.footer {
  padding: 10px 0px;
  position: relative;
  margin-top: 80px;
}

.footer_flexed_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer_flexed_item {
  margin-right: 30px;
}

.foot_sub_heading {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
}

.footer_text {
  color: black;
  margin-bottom: 12px;
}

.footer_social {
  color: black;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.footer_social:hover {
  color: #222f55;
}

.foot_sub_heading {
  margin-top: 20px;
  margin-bottom: 10px;
}

.flex_mapped {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer_email, .footer_phone {
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer_icon {
  color: black;
  padding-right: 5px;
}

.footer_absolute_top_border {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 10px;
  background: #bdbdbd;;
}

.footer_absolute_bottom_border {
  -webkit-transform: skewX(-40deg);
      -ms-transform: skewX(-40deg);
          transform: skewX(-40deg);
  position: absolute;
  bottom: 0px;
  right: -50px;
  width: 500px;
  height: 30px;
  background: #000000;
}

.footer_absolute_bottom_border_next {
  -webkit-transform: skewX(-40deg);
      -ms-transform: skewX(-40deg);
          transform: skewX(-40deg);
  background-color: red;
  position: absolute;
  bottom: 0px;
  right: 460px;
  width: 30px;
  height: 30px;
  background: #222F55;
}

.section_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 160px;
  padding-bottom: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff176;
  background-image: -webkit-gradient(linear, left bottom, right top, from(#283593), color-stop(#043b8c), color-stop(#003e82), color-stop(#004076), to(#004169));
  background-image: -o-linear-gradient(left bottom, #283593, #043b8c, #003e82, #004076, #004169);
  background-image: linear-gradient(to right top, #283593, #043b8c, #003e82, #004076, #004169);
  background-image: -webkit-gradient(linear, right top, left bottom, from(#282d64), color-stop(#223367), color-stop(#1d3869), color-stop(#193d6b), to(#17426c));
  background-image: -o-linear-gradient(right top, #282d64, #223367, #1d3869, #193d6b, #17426c);
  background-image: linear-gradient(to left bottom, #282d64, #223367, #1d3869, #193d6b, #17426c);
  background: #2c3d6d;
}

.section_title_border {
  background-image: url('./resources/floor-tile.png');
  width: 100%;
  height: 30px;
  margin-bottom: 50px;
}

.section_title > h2 {
  font-weight: 500;
  font-size: 30px;
}

.about_text_section {
  margin: 30px 0px;
}

.international_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0px 80px;
  margin-top: 30px;
  margin-bottom: 70px;
}

.international_link {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 30px;
  border-top: 1px solid;
  padding-top: 30px;
  border-color: #e0e0e0;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.link_name_logo_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.international_link_name {
  font-size: 20px;
  font-weight: 600;
  color: #616161;
  margin: 0px;
  margin-right: 20px;
}

.international_link_logo {
  width: 200px;
}

.international_link_desc {
  font-size: 14px;
}

.pro_bono_flex {
  padding: 60px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pro_bono_container {
  margin-bottom: 100px;
}

.pro_bono_text, .pro_bono_image {
  width: 50%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.pro_bono_text {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pro_bono_banner {
  width: 95%;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
}

.contact_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 50px;
}

.contact_form, .google_map {
  width: 48%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.contact_form {
  padding: 40px;
  background: #eee;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.08);
          box-shadow: 0 6px 12px rgba(0,0,0,0.08);
}

.google_map {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.office_hours {
  font-size: 18px;
  font-family: 'Alata', sans-serif;
  text-align: center;
  margin: 40px 0px;
}

.contact_button {
  margin-top: 15px;
}

.contact_social {
  margin-top: 20px;
}

.contact_social_heading {
  font-size: 26px;
}

.contact_social_icon {
  color: #2a2f35;
  -webkit-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
  margin-right: 10px;
  cursor: pointer;
}

.contact_social_icon:hover {
  color: #222f55;
}

.contact_thankyou {
  font-weight: 600;
  color: #757575;
  margin-bottom: 0px;
}

.contact_locations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 40px;
}

.contact_location {
  width: 32%;
  padding: 20px 20px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.contact_location:nth-child(odd) {
  background: #222F55;
}

.contact_location:nth-child(even) {
  background: #222F55;
}

.contact_location_info_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
  min-height: 50px;
}

.contact_location_icon {
  margin-right: 10px;
  font-size: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.contact_location_heading {
  font-size: 24px;
  font-family: 'Alata', sans-serif;
  margin: 0px;
  color: #FFEB3B;
}

.contact_location_separator {
  width: 75px;
  height: 4px;
  background: white;
  margin: 20px 0px;
}

.contact_location_address {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
}

.contact_email_section  {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
}

.contact_email_icon {
  margin-right: 5px;
}

.contact_email_text {
  margin: 0px;
}

.contact_email, .contact_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form-control {
  border-radius: 0px !important;
}

.flexed_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flexed_form_group {
  width: 49%;
}

.text-muted {
  font-size: 11px !important;
}

.career_title_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.career_title_desc {
  text-align: center;
}

.career_image {
  width: 37%;
}

.career_axis_image {
  width: 100%;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
}

.photos_content {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.career_title_flex {
  width: 45%;
  padding-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.career_title_slider {
  width: 52%;
}

.axis_office_images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.axis_office_image {
  width: 100%;
}

.career_opp_flex {
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.08);
          box-shadow: 0 6px 12px rgba(0,0,0,0.08);
  background-color: white;
  position: relative;
  padding: 45px 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 620px;
  overflow: hidden;
}

.career_opp {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#AxisSummerInternshipProgramme_desc {
  display: none;
}

.career_opp_image {
  width: 30%;
}

.career_opp_container {
  width: 70%;
}

.career_opp > div > div > p {
  font-size: 14px;
}

.job_listings_title {
  font-size: 36px;
  font-weight: 300;
}

.no_jobs {
  color: #757575;
  font-weight: 600;
}

.job_details_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.job_details {
  width: 70%;
}

.job_apply_pane {
  width: 28%;
  margin-left: 2%;
  background: #eee;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 20px;
}

.job_detail_title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px;
}

.job_detail_text {
  font-size: 16px;
  margin: 6px 0px;
}

.deadline {
  margin-bottom: 25px;
  font-weight: 600;
}

.jobs_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 50px;
}

.job {
  padding: 25px 25px;
  width: 31%;
  margin-right: 2%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.16);
}

.job_button {
  margin-top: 30px;
}

.job_title {
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0px;
  color: #424242;
}

.articles_container, .oped_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.article_listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 25px 25px;
  border-bottom: 1px solid;
  border-color: #bdbdbd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.article_listing_text {
  width: 68%;
}

.article_listing_img {
  width: 30%;
}

.article_image {
  width: 100%;
}

.article_type {
  text-transform: uppercase;
  font-family: 'Alata', sans-serif;
  font-size: 12px;
  margin-bottom: 8px;
}

.article_title {
  font-size: 18px;
  font-family: 'Alata', sans-serif;
}

.article_desc {
  font-size: 14px;
}

.article_author {
  font-size: 12px;
  font-weight: 600;
  margin-top: -8px;
}

.bold {
  font-weight: 600;
}

.normal {
  font-weight: 400;
}

.article_written_by, .article_disclaimer {
  font-size: 18px;
  font-weight: 600;
}

.article_written_by {
  margin-bottom: 40px;
}

.article_disclaimer {
  margin-top: 60px;
}

.article_image {
  width: 75%;
  margin-left: 12.5%;
  margin-bottom: 40px;
}

.announcement_detail > .markdown > div > p > img {
  width: 55%;
  margin-left: 22.5%;
  margin-bottom: 30px;
  margin-top: 30px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.08);
          box-shadow: 0 6px 12px rgba(0,0,0,0.08);
}

.active_team_nav {
  color: #fff;
  border-color: #12195B;
  background: #12195B;
  font-weight: 600;
}

.associate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid;
  padding: 30px;
  border-color: #e0e0e0;
  margin: 60px 0px;
  position: relative;
  overflow: hidden;
  background-image: -webkit-gradient(linear, left bottom, right top, from(#fcfcfc), color-stop(#fdfdfd), color-stop(#fefefe), color-stop(#fefefe), to(#ffffff));
  background-image: -o-linear-gradient(left bottom, #fcfcfc, #fdfdfd, #fefefe, #fefefe, #ffffff);
  background-image: linear-gradient(to right top, #fcfcfc, #fdfdfd, #fefefe, #fefefe, #ffffff);
  background-image: url('./resources/climpek2.png');
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.08);
          box-shadow: 0 6px 12px rgba(0,0,0,0.08);
}

.associate_absolute_border {
  position: absolute;
  right: 0px;
  height: 10px;
  bottom: 0px;
  top: auto;
  width: 300px;
  background: #3E2D4C;
}

.associate_image {
  width: 20%;
  margin-right: 2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.avatar {
  width: 90%;
}

.associate_info {
  width: 78%;
}

.associate_name {
  font-size: 18px;
  font-weight: 600;
  color: #424242;
  margin-bottom: 8px;
}

.associate_desig {
  font-weight: 600;
  color: #222F55;
  margin-bottom: 10px;
}

.associate_desc {
  font-size: 14px;
}

.partners_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 60px 0px;
}

.partner {
  width: 32%;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.08);
          box-shadow: 0 6px 12px rgba(0,0,0,0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid;
  height: 100%;
  padding: 30px;
  border-color: #e0e0e0;
  position: relative;
  background: #f5f5f5;
  background-image: url('./resources/black-thread-light.png');
  -webkit-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
  transition: 0.5s ease-in;
  min-height: 360px;
  overflow: hidden;
}

.partner:hover {
  background: #fff;
  background-image: url('./resources/climpek2.png');
}

.partner_image_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.partner_image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80%;
}

.partner_name_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.partner_info {
  width: 100%;
  min-height: 112px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.partner_info > ul {
  padding: 0px 20px;
}

.partner_separator {
  width: 75px;
  height: 4px;
  background-color: #757575;
  margin-bottom: 10px;
  display: none;
}

.partner_buttons {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #000000;
  color: white;
}

.partner_button_tilt {
  position: absolute;
  bottom: 13px;
  -webkit-transform: skewY(-0.6deg);
      -ms-transform: skewY(-0.6deg);
          transform: skewY(-0.6deg);
  width: 100%;
  height: 10px;
  background: #000000;
}

.view_profile_fade {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.bar_memberships {
  white-space: pre-line;
  text-align: center;
  font-size: 14px;
  margin-bottom: 25px;
  display: none;
}

.partner_detail_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.partner_detail_info {
  width: 35%;
  margin-right: 2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-right: 1px solid;
  border-color: #bdbdbd;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.partner_detail_info > ul {
  padding: 0px 20px;
}

.partner_detail_image {
  width: 60%;
  margin-bottom: 20px;
}

.partner_detail_title {
  margin-bottom: 5px;
}

.partner_detail_desc {
  width: 63%;
}

.chambers_partner_badge {
  margin-top: 20px;
  width: 150px;
}

.partner_publications_container {
  margin-top: 40px;
}

.partner_publications {
  font-size: 18px;
  font-weight: 600;
}

.partner_publications_container > ul {
  padding: 0px 20px;
}

.partner_publications_container > ul > li > a {
  text-decoration: none;
  color: #616161;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.partner_publications_container > ul > li > a:hover {
  color: #000000;
}

.map_city_toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 30px;
}


.city_toggle_button {
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  cursor: pointer;
}

.city_toggle_button:hover {
  color: #000000;
}

.city_toggle_selected {
  color: #d38b41;
  border-bottom: 2px solid;
  border-color: #2a2f35;
  font-weight: 600;
}

.areas_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 60px;
  border-top: 1px solid;
  border-color: #e0e0e0;
  padding-top: 45px;
}

.area {
  width: 33.3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
}

.area > p {
  margin: 0px;
  font-weight: 500;
  -webkit-transition: 0.1s ease-out;
  -o-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
}

/**
.industry {
  cursor: pointer;
}
.industry > p:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
  font-weight: 600;
  color: #616161;
}
**/

.contact_image_container {
  margin-bottom: 30px;
}

.small_industry_container {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  border-top: 0px;
  border-bottom: 1px solid;
  border-color: #e0e0e0;
}

.industry_smaller {
  width: 19%;
  font-size: 13px;
}

.services_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 60px;
}

.services_navigation {
  width: 28%;
  border: 1px solid;
  border-color: #e0e0e0;
}

.services_navigation > .nav > .nav-item > .nav-link {
  border-radius: 0px;
  color: #757575;
}

.services_navigation > .nav > .nav-item > .active {
  background: #51425E;
  color: white;
}

.services_description {
  width: 68%;
  min-height: 450px;
}

.capitalize {
  text-transform: capitalize;
}

.service {
  width: 100%;
  padding: 20px 0px;
}

.service > p:first-child {
  font-size: 22px;
  font-weight: 600;
  color: #12195B;
}

.service > p:nth-child(2) {
  font-size: 14px;
}

.service_border {
  width: 75px;
  height: 5px;
  margin-bottom: 13px;
  background: #3E2D4C;
}

h2 {
  color: #12195B;
}

.news_highlight {
  padding: 30px;
  cursor: pointer;
}

.news_highlight > .subheading {
  text-align: left;
}

.news_highlight_img {
  width: 100%;
  margin-bottom: 30px;
}

.news_highlight_desc {
  font-size: 14px;
  margin-top: 18px;
}


/** Custom Tabs **/

.custom_tabs {
  margin-top: 5px;
  margin-bottom: 40px;
  font-size:15px;
  padding:0px;
  list-style:none;
  background:#fff;
  -webkit-box-shadow:0px 5px 20px rgba(0,0,0,0.1);
          box-shadow:0px 5px 20px rgba(0,0,0,0.1);
  display:inline-block;
  border-radius:0px;
  position:relative;
  cursor: pointer;
}

.custom_tabs div {
  text-decoration:none;
  width: 225px;
  text-align: center;
  color: #777;
  text-transform:uppercase;
  padding:10px 20px;
  display:inline-block;
  position:relative;
  z-index:1;
  -webkit-transition-duration:0.6s;
       -o-transition-duration:0.6s;
          transition-duration:0.6s;
}

.custom_tabs div.active {
  color:#fff;
  -webkit-transition-duration:0.6s;
       -o-transition-duration:0.6s;
          transition-duration:0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
       -o-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background: #3E2D4C;
  background: #3E2D4C;

}

.custom_tabs div i {
  margin-right:5px;
}

.slides > .slick-prev {
  left: 1% !important;
  z-index: 1;
}
.slides > .slick-next {
  right: 1% !important;
  z-index: 1;
}

.slides .slick-dots li button {
  font-family: 'slick' !important;
}

.slick-prev:before,
.slick-next:before {
  color: #757575 !important;
}

.slick-slide {
  visibility: hidden;
}
.slick-slide.slick-active {
  visibility: visible;
}

@media only screen and (min-width: 1750px) {
  .pad_nav_logo {
    margin-left: 250px;
  }

  .navbar-toggler, .justify-content-end {
    margin-right: 250px;
  }

  .content_container {
    margin: 35px 250px;
  }
  .background_nav_logo {
    width: 450px;
  }
  .partner {
    min-height: 430px;
  }
  .axis_main_banner {
    font-size: 86px;
  }
  .axis_main_separator {
    width: 185px;
  }
  .partner_detail_title, .article_title, .navbar-dark .navbar-nav .nav-link  {
    font-size: 20px;
  }
  .home_about_content > p, .home_service_description, .home_news_banner_desc, .centered_paragraphs, .pro_bono_text > p, .tab-pane > .react-reveal > div > p, .area > p, .partner_detail_desc, .career_title_desc, .career_opp_container > div > p, .news_highlight_desc, .article_desc, .contact_location_address{
    font-size: 18px !important;
  }
  .home_news_other_desc, .international_link_desc, .associate_desc {
    font-size: 16px;
  }
  .article_type {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1150px) {
  .content_container {
    margin: 35px 80px;
  }
  .pad_nav_logo {
    margin-left: 80px;
  }
  .navbar-toggler , .justify-content-end {
    margin-right: 80px;
  }
  .background_nav_logo {
    width: 225px;
  }
  .padded_link {
    margin: 0px 5px;
  }
  .partner {
    min-height: 375px;
  }
  .contact_image_container {
    width: 100%;
  }
  .contact_image {
    width: 100%;
  }
  .contact_image_o {
    width: 100%;
  }
}


@media only screen and (max-width: 991px) {
  .navbar {
    height: 80px;
  }
  .background_nav_logo_container {
    height: 80px;
  }
  .padded_link {
    margin: 2px 80px;
  }
  .justify-content-end {
    margin: 0px;
  }
  .navbar-collapse {
    background: rgba(44, 61, 109, 0.85);
    margin: 0px -16px !important;
    padding: 0px 16px;
    z-index: 2000;
  }
  .home_news_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .home_news_banner_section {
    width: 100%;
  }
  .home_news_other_section {
    width: 100%;
  }
  .custom_tabs div {
    width: 190px;
  }
  .home_locations_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .home_location {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0px !important;
  }
  .home_location_loc {
    width: auto;
  }
  .home_location:first-child, .home_location:nth-child(2) {
    border: none;
  }
  .career_title_flex {
    width: 100%;
    margin-bottom: 30px;
  }
  .career_title_container {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .career_title_desc {
    text-align: center !important;
  }
  .career_title_slider {
    width: 80%;
  }
  .photos_content {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .career_opp {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .career_opp_image {
    width: 60%;
  }
  .career_opp_container {
    width: 100%;
  }
  .contact_flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .contact_form, .google_map {
    width: 100%;
  }
  .contact_form {
    width: 80%;
    margin-left: 10%;
  }
  .contact_image_container {
    width: 70%;
  }
  .contact_image {
    width: 100%;
  }
  .contact_image_o {
    width: 100%;
  }
  .contact_location {
    width: 49%;
    margin-bottom: 20px;
  }

  .partner {
    width: 49%;
    min-height: 375px;
  }
  .partner_image > .avatar {
    width: 80%;
  }

  .associate_image > .avatar {
    width: 90%;
  }

  .associate_info {
    width: 70%;
  }
  .associate_image {
    width: 30%;
  }
  .associate_desc {
    font-size: 13px;
  }
  .service_desc {
    font-size: 14px;
  }
  .footer_flexed_section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .centered_paragraphs {
    margin: 0px 40px;
    margin-top: 40px;
  }
  .contact_image_container {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .content_container {
    margin: 35px 50px;
  }
  .pad_nav_logo {
    margin-left: 50px;
  }
  .navbar-toggler , .justify-content-end {
    margin-right: 50px;
  }
  .background_nav_logo {
    width: 195px;
  }
  .padded_link {
    margin: 2px 50px;
  }
  .contact_form {
    width: 100%;
    margin-left: 0px;
  }
  .contact_image_container {
    width: 100%;
  }
  .contact_location {
    width: 100%;
  }
  .centered_paragraphs {
    margin: 0px;
    margin-top: 20px;
  }
  .pro_bono_flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .pro_bono_image {
    width: 100%;
  }
  .pro_bono_text {
    padding: 0px;
    width: 100%;
    margin-top: 30px;
  }
  .custom_tabs div {
    width: 155px;
    font-size: 11px;
  }
  .custom_tabs {
    margin-bottom: 10px;
  }
  .areas_container {
    margin-top: 0px;
  }
  .services_navigation {
    width: 100%;
    margin-top: -30px;
  }
  .services_description {
    width: 100%;
    margin-top: 30px;
  }
  .services_navigation > .nav > .nav-item > .nav-link {
    font-size: 12px;
  }
  .partner {
    width: 70%;
    margin-left: 15%;
    min-height: 375px
  }
  .associate {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .associate_image {
    width: 50%;
    margin-bottom: 10px
  }
  .associate_info {
    width: 100%;
  }
  .home_services_container, .career_opp_flex {
    padding: 30px 60px;
  }
  .career_title_slider {
    width: 100%;
  }
  .home_location > svg {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .content_container {
    margin: 35px 35px;
  }
  .pad_nav_logo {
    margin-left: 35px;
  }
  .navbar-toggler , .justify-content-end {
    margin-right: 35px;
  }
  .background_nav_logo {
    width: 185px;
  }
  .footer_absolute_bottom_border {
    width: 300px;
  }
  .footer_absolute_bottom_border_next {
    right: 260px;
  }
  .footer_absolute_top_border {
    width: 225px;
  }

  .custom_tabs div {
    width: 100%;
    font-size: 13px;
  }
  .custom_tabs {
    margin-bottom: 30px;
  }
  .partner {
    width: 100%;
    margin-left: 0px;
  }
  .axis_main_banner {
    font-size: 62px;
  }
  .axis_main_separator {
    width: 125px;
  }
  .home_services_container, .career_opp_flex {
    padding: 20px 30px;
  }
  .career_opp_image {
    width: 80%;
  }

  .international_links {
    margin: 0px;
  }
  .international_link_logo {
    margin-top: 10px;
  }
  .international_link_desc {
    text-align: left;
  }
  .career_title_desc {
    text-align: left !important;
  }
  .link_name_logo_flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .partner_image > .avatar {
    width: 70%;
  }
  .home_about_content > p {
    text-align: left;
  }
  .centered_paragraphs {
    text-align: left;
  }
  .home_locations_container {
    margin: 0px 20px;
  }
  .article_listing {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .article_listing_text {
    width: 100%;
  }
  .article_listing_img {
    width: 100%;
  }
  .footer_flexed_item > .flex_mapped {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .industry_smaller {
    width: 32%;
  }
  .partner_detail_flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .partner_detail_info {
    width: 75%;
    border: 0px;
    margin-bottom: 30px;
  }
  .partner_detail_desc {
    width: 100%;
  }
}

@media only screen and (max-width: 440px) {
  .content_container {
    margin: 20px 20px;
  }
  .pad_nav_logo {
    margin-left: 20px;
  }
  .navbar-toggler , .justify-content-end {
    margin-right: 20px;
  }
  .background_nav_logo {
    width: 155px;
  }
  .footer_absolute_bottom_border {
    width: 250px;
  }
  .footer_absolute_bottom_border_next {
    right: 210px;
  }
  .footer_absolute_top_border {
    width: 185px;
  }
  .heading, .home_news_banner_title, .section_title > h2 {
    font-size: 24px;
  }
  .section_title > h2 {
    text-align: center;
  }
  .subheading {
    font-size: 20px;
  }
  .axis_main_banner {
    font-size: 60px;
  }
  .industry_smaller {
    width: 48%;
  }
  .area {
    width: 48%;
    padding: 8px 6px;
    font-size: 13px;
  }
  .partner {
    min-height: 275px;
  }
  .partner_detail_info {
    width: 100%;
  }
  .flexed_form_group {
    width: 100%;
  }
  .slick-slider {
    width: 90% !important;
  }
}
